@use "../config/" as *;
%pdf-link-styles {
  a {
    line-height: 1.5;
    @extend %flex;
    @extend %c-black-6;
    @extend %gap-full;
    &::before {
      content: "PDF";
      width: 4.8rem;
      height: 2.2rem;
      border-radius: .5rem;
      padding-inline: .5rem;
      @extend %flex-c-c;
      @extend %c-orange-bg-10;
      @extend %font-12-pb;
      @extend %c-pure-white-10;
    }
    &[href$=".docx"],
    &[href$=".doc"] {
      &::before {
        content: "WORD";
      }
    }
  }
}
.waf-inside-governance,
.waf-inside-development,
.waf-inside-common,
.waf-inside-common .content > div:last-child:not([class]) {
  h2 {
    margin-block: 0 var(--one-n-half-space);
    line-height: 1.2;
    @extend %c-blue-2-10;
    @extend %font-20-pb;
    strong,
    em {
      margin-bottom: 0;
      font-size: inherit;
    }
  }
  // Styles for pdf links
  ul {
    &:has(a[href$='.doc']),
    &:has(a[href$='.docx']),
    &:has(a[href$='.pdf']) {
      padding-left: 0;
      li {
        list-style-type: none;
        @extend %pdf-link-styles;
        &:not(:last-child) {
          @extend %m-b-one-n-half;
        }
      }
    }
  }
  .embedded-entity {
    @extend %m-b-one-n-half;
    @extend %pdf-link-styles;
  }
}
.waf-inside-governance,
.waf-inside-development,
.waf-inside-common .content > div:last-child:not([class]) {
  h1 {
    margin-block: 0 4.5rem;
    line-height: 1.5;
    @extend %c-blue-10;
    @extend %font-24-pb;
  }
  h3,
  h4 {
    @extend %c-blue-2-10;
    strong,
    em {
      margin-bottom: 0;
      font-size: inherit;
    }
  }
  h3 {
    margin-block: 0 var(--one-n-half-space);
    line-height: 1.3;
    @extend %font-16-pb;
  }
  h4 {
    margin-block: 0 var(--full-space);
    line-height: 1.33;
    @extend %font-14-pb;
  }
  div {
    line-height: 1.5;
    @extend %m-b-one-n-half;
    @extend %c-black-6;
  }
  p {
    line-height: 1.5;
    @extend %m-b-one-n-half;
    @extend %c-black-6;
    strong {
      font-family: $font-pb;
      @extend %c-blue-1-10;
    }
    strong,
    em,
    span {
      font-size: inherit;
    }
  }
  em {
    font-style: italic;
  }
  iframe {
    width: min(85.3rem, 100%);
    height: auto;
    aspect-ratio: 16/9;
  }
  table {
    width: 100% !important;
    border-collapse: collapse;
    word-break: break-word;
  }
  hr {
    @extend %d-none;
  }
  #banner {
    overflow-x: auto !important;
  }
  ol,
  ul {
    @extend %p-l-full;
  }
  ol {
    list-style-type: decimal;
  }
  ol,
  ul {
    margin-bottom: 3rem;
  }
  ul {
    list-style-type: disc;
  }
  li {
    line-height: 1.5;
    @extend %c-black-6;
    strong,
    em,
    span {
      font-size: inherit;
    }
  }
  .text-align-justify {
    text-align: justify;
  }
  .text-align-center {
    text-align: center;
  }
  // Therapeutic Use Exemptions address styles
  span[style="font-size:11pt"] {
    font-size: 1.4rem !important;
    span > span[style="font-family:Calibri,sans-serif"] {
      font-family: $font-pr !important;
    }
  }
  // Image styles
  p {
    &:has(img[data-entity-type="file"]) {
      @extend %m-b-two-space;
    }
    strong + img[data-entity-type="file"] {
      @extend %m-t-full;
    }
  }
  img[data-entity-type="file"] {
    max-width: 100%;
    width: auto;
    height: auto;
  }
  // Styles for Accordion
  ul + p:has(a > img) {
    margin-top: -1.8rem;
  }
}
.waf-inside-governance {
  // Styles for table with file name & download link
  .table.table-striped {
    border: 0;
    thead {
      height: unset;
      background-color: unset;
    }
    tr {
      height: unset;
      display: grid;
      grid-template-columns: 60% auto;
      @extend %p-x-full;
      @extend %gap-full;
    }
    th {
      line-height: 2.2rem;
      @extend %p-y-full;
      @extend %c-blue-2-10;
      @extend %text-left;
      &:not(:first-child) {
        @extend %relative;
        &::before {
          content: "";
          width: .2rem;
          height: 1.4rem;
          pointer-events: none;
          @include position-combo("y-center", null, var(--full-space-negative));
          @extend %c-black-bg-2;
        }
      }
    }
    tbody {
      @extend %p-x-full;
      @extend %c-light-grey-1-bg-2;
      tr {
        border-bottom: .1rem solid var(--c-light-grey-1);
      }
    }
    td {
      height: unset;
      font: 700 1.6rem/1.5 $font-pb;
      font-size: 1.6rem;
      @extend %p-y-one-n-half;
      @extend %c-blue-10;
    }
  }
}
.waf-inside-development {
  table {
    &:not(:last-child) {
      @extend %m-b-one-n-half;
    }
    &.table-striped td {
      vertical-align: top;
    }
    &:not(.table-striped) {
      overflow-x: auto;
      @extend %d-block;
    }
  }
  h5 button {
    font-size: 1.8rem;
    font-weight: bold;
    @extend %c-blue-10;
    @extend %text-left;
  }
}
@media screen and (min-width: $tablet-min-width) {
  .waf-inside-governance,
  .waf-inside-development,
  .waf-inside-common,
  .waf-inside-common .content > div:last-child:not([class]) {
    h2 {
      font-size: 2.4rem;
    }
  }
  .waf-inside-governance,
  .waf-inside-development,
  .waf-inside-common .content > div:last-child:not([class]) {
    h1 {
      font-size: 3.6rem;
    }
    h3 {
      font-size: 1.8rem;
    }
    h4,
    p,
    li {
      font-size: 1.6rem;
    }
    a {
      font-size: 1.6rem;
    }
    // Therapeutic Use Exemptions address styles
    span[style="font-size:11pt"] {
      font-size: 1.6rem !important;
    }
    .table.table-striped tr {
      grid-template-columns: 70% auto;
    }
  }
  .waf-inside-development table:not(.table-striped) {
    display: table;
  }
}