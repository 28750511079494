@use '../config/' as *;
//Common Breadcrumb styles
// Hide chevron down for Member Detail page
.nf-breadcrumb .waf-site-inside-nav .btn-open {
  @extend %d-none;
}
.inside-uww-page {
  .main-wrap {
    padding-top: calc(var(--header-height) + var(--inside-page-breadcrumb-height));
  }
  .breadcrumb-wrap {
    @extend %d-block;
  }
  &.nf-breadcrumb {
    .main-wrap {
      padding-top: var(--header-height);
    }
    .breadcrumb-wrap {
      @extend %d-none;
    }
  }
}
.waf-row-inside-page {
  .waf-view > .container {
    padding-inline: 0;
  }
  .sticky-container {
    margin-inline: 0;
  }
  [class^="col-"] {
    padding-inline: 0;
  }
  // Inside UWW Common filter overrides 
  .filter-section {
    padding-inline: var(--full-space);
    padding-bottom: 1rem;
    border-bottom: .1rem solid hsl(var(--hsl-c-black) / .05);
    margin-inline: var(--full-space-negative);
    .filter {
      &-title {
        color: var(--c-blue);
      }
      &-toggle {
        border-color: var(--c-grey);
        .btn-text::after {
          color: var(--c-blue);
        }
      }
      &-body {
        max-height: calc(var(--window-inner-height) - var(--header-height) - var(--inside-page-breadcrumb-height) - 7.3rem);
        top: calc(var(--header-height) + var(--inside-page-breadcrumb-height));
      }
    }
    .search {
      &-wrap {
        @extend %m-b-half;
      }
      &-input {
        background: unset;
        height: 4rem;
        border: unset;
        border-bottom: .1rem solid var(--c-blue-light);
        @include placeholder(var(--c-grey));
        @extend %w-100;
        @extend %flex-n-c;
        @extend %c-white-10;
        @extend %font-16-pr;
      }
    }
  }
  .waf-squad {
    @extend %p-x-full;
    .waf-head {
      @extend %d-none;
    }
    .filter-section {
      @extend %m-x-zero;
      .no-data-wrap {
        @extend %d-block;
      }
    }
  }
}
.waf-inside-page,
.waf-inside-governance,
.waf-inside-development {
  padding-block: var(--two-space) 6.4rem;
  margin-block: 0;
}
.waf-inside-banner {
  padding: 0;
  margin: 0 var(--half-space-negative);
  .layout-wrapper {
    max-width: unset;
  }
  .content {
    padding: var(--two-space) var(--full-space) 6.4rem;
    @extend %p-x-full;
  }
}
.waf-inside-default {
  padding: var(--two-space) var(--full-space);
  margin: 0 var(--half-space-negative);
}
@media (min-width: $tablet-min-width) {
  // Hide breadcrumb in desktop reso for Member Detail page
  .nf-breadcrumb .waf-row-inside-page {
    padding-top: 8rem;
  }
  .inside-uww-page .main-wrap {
    padding-top: var(--header-height);
  }
  .waf-row-inside-page {
    padding-bottom: 4rem;
    @include white-bg();
    .site-inside-nav {
      margin-right: calc(var(--container-white-space) / 2);
    }
    .filter-section {
      padding-inline: 0;
      border-bottom: none;
      margin-inline: 0;
      margin-bottom: 4.5rem;
      .filter {
        &-body {
          width: 100%;
          max-height: unset;
          top: unset;
          align-items: center;
          gap: 2rem var(--full-space);
        }
        &-group {
          flex-grow: 1;
          flex-basis: unset;
          gap: var(--full-space);
          .waf-select-box {
            max-width: 19.3rem;
            min-width: 14rem;
            margin-right: 0;
            margin-bottom: 0;
            flex-grow: 1;
          }
        }
        &-actions {
          width: 12.4rem;
          padding: 0;
          margin-left: auto;
          display: block;
          position: unset;
          .btn-clear {
            display: none;
          }
          .btn-apply {
            width: 12.4rem;
            height: 4rem;
            border-radius: 100vmax;
            background-color: var(--c-blue);
            @include flex-config(flex, null, center, center);
            .btn-text {
              color: var(--c-white);
            }
          }
        }
      }
      .selected-label {
        height: 4.6rem;
        font-size: 1.8rem;
        &::after {
          top: 50%;
          font-size: 2.4rem;
          transform: translate(0, -50%);
        }
      }
      &.w-100 {
        .filter-group {
          width: 100%;
          .waf-select-box {
            flex: 1;
          }
        }
      }
      .select-box-wrap {
        padding-top: var(--half-space);
        button,
        .list-item-text {
          font-size: 1.6rem;
        }
        .select-list {
          padding-top: 0;
        }
        .list-item {
          padding-block: .6rem;
          .list-item-input,
          .list-item-option::after,
          .list-item-option .list-item-text::after {
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
      .search {
        &-wrap {
          padding-inline: var(--half-space);
          margin-bottom: 0;
        }
        &-input {
          color: var(--c-blue);
        }
      }
    }
    .waf-squad {
      padding: 0 0 var(--two-space);
      margin-block: 0;
      .layout-wrapper {
        max-width: unset;
      }
      .filter-section .filter-body {
        width: 100%;
      }
      .card-list {
        padding-inline: 0;
      }
      .name {
        overflow-wrap: anywhere;
      }
    }
  }
  .waf-inside-page,
  .waf-inside-governance,
  .waf-inside-development {
    padding: 0 0 10rem;
    .layout-wrapper {
      max-width: unset;
    }
  }
  .waf-inside-banner .content {
    max-width: var(--container-max-width);
    padding: 7rem 0 10rem;
    margin-inline: auto;
  }
  .waf-inside-default {
    padding: 0 0 7rem;
  }
}
@media (min-width: $large-desktop-min-width) {
  .waf-row-inside-page {
    .waf-view > .container {
      max-width: calc(100% - 2 * var(--container-white-space));
    }
    .site-inside-nav {
      margin-right: var(--container-white-space);
    }
    .waf-squad .card-item {
      flex-basis: 25%;
    }
  }
  .waf-inside-banner .content {
    max-width: calc(100% - 16rem);
    margin-inline: 8rem;
  }
}