@use "../config/" as *;
// breadcrumb btn styles
.waf-site-inside-nav {
  height: 0;
  .btn-open {
    @extend %font-zero;
    &::after {
      content: "\e806";
      z-index: var(--zindex-inside-breadcrumb);
      font: 3.6rem/1 $font-icon;
      transition: transform .3s ease-in;
      @include position(calc(var(--header-height) + .6rem), 1.2rem, null, null, fixed);
      @extend %c-black-10;
    }
    &[aria-expanded="true"]::after {
      transform: rotate(180deg);
    }
  }
}
.site-inside-nav {
  max-height: calc(var(--window-inner-height) - var(--header-height) - var(--inside-page-breadcrumb-height));
  z-index: var(--zindex-inside-nav);
  overflow-y: auto;
  @include position(calc(var(--header-height) + var(--inside-page-breadcrumb-height)), null, null, 0, fixed);
  @extend %w-100;
  @extend %d-none;
  @extend %relative;
  &::before {
    content: "";
    height: var(--window-inner-height);
    z-index: var(--zindex-pattern);
    @include position-combo("tl", null, null, fixed);
    @extend %w-100;
    @extend %c-black-bg-8;
  }
  &.active {
    @extend %d-block;
  }
  .nav-list {
    background-color: var(--c-ghost-white);
  }
  .active.submenu > {
    .nav-anchor,
    .sub-nav-anchor {
      &::after {
        content: unset;
      }
    } 
    .sub-nav {
      max-height: 100%;
      opacity: 1;
    }
  }
  .submenu {
    > .nav-anchor,
    > .sub-nav-anchor {
      gap: var(--full-space);
      justify-content: space-between;
      &::after {
        content: '\e819';
        color: var(--c-orange);
        font: 2.8rem/1 $font-icon;
      }
    }
  }
  .nav-anchor,
  .sub-nav-anchor,
  .btn-nav-anchor {
    border-bottom: .1rem solid var(--c-grey);
    height: 4.8rem;
    cursor: pointer;
    @extend %w-100;
    @extend %p-x-full;
    @extend %flex-n-c;
    @extend %c-blue-2-10;
    @extend %font-16-pr;
  }
  .btn-nav-anchor {
    gap: var(--full-space);
    justify-content: space-between;
    &::after {
      content: '\e819';
      color: var(--c-orange);
      font: 2.8rem/1 $font-icon;
    }
    &[aria-expanded="true"] {
      &::after {
        content: "\e85a";
      }
      ~ .sub-nav {
        max-height: 100%;
        opacity: 1;
      }
    }
  }
  .btn-text,
  .nav-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .active {
    &:not(.primary) > .sub-nav-anchor {
      color: var(--c-orange);
    }
    .nav-anchor {
      background-color: var(--c-bright-gray);
      font-family: $font-pb;
      font-weight: 700;
    }
    &.secondary > .sub-nav-anchor {
      font-family: $font-psb;
      font-weight: 600;
    }
  }
  .primary {
    > .btn-nav-anchor[aria-expanded='true'] {
      background-color: var(--c-bright-gray);
      font-family: $font-pb;
      font-weight: 700;
    }
  }
  .secondary {
    .sub-nav-anchor,
    .btn-nav-anchor {
      padding-left: 3rem;
    }
    > .btn-nav-anchor[aria-expanded='true'] {
      font-family: $font-psb;
      font-weight: 600;
    }
  }
  .third {
    > {
      .sub-nav-anchor,
      .btn-nav-anchor {
        padding-left: 5rem;
      }
    }
  }
  .fourth > {
    .sub-nav-anchor,
    .btn-nav-anchor {
      padding-left: 6rem;
    }
  }
  .fifth > {
    .sub-nav-anchor,
    .btn-nav-anchor {
      padding-left: 7rem;
    }
  }
  .sub-nav {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    background-color: var(--c-cultured);
    transition: max-height .3s ease-in, opacity .3s ease-in;
  }
}
@media (min-width: $tablet-min-width) {
  .waf-site-inside-nav {
    height: auto;
    .btn-open {
      display: none;
    }
  }
  .site-inside-nav {
    max-height: unset;
    width: unset;
    margin-bottom: 8rem;
    display: block;
    position: static;
    overflow-y: unset;
    &::before {
      content: unset;
    }
    .nav-list {
      background-color: transparent;
    }
    .nav-text {
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
    }
    .nav-anchor,
    .sub-nav-anchor,
    .btn-nav-anchor {
      height: unset;
      padding-block: 1.2rem;
      border-color: var(--c-light-grey-1);
    }
    .btn-nav-anchor::after{
      font-size: 2.2rem;
    }
    .submenu > {
      .nav-anchor::after, .sub-nav-anchor::after {
        font-size: 2.2rem;
      }
    }
    .nav-anchor,
    .btn-nav-anchor,
    .sub-nav-anchor {
      color: var(--c-blue-1);
      &:hover {
        color: var(--c-blue-2);
      }
    }
    .active {
      .nav-anchor {
        background-color: hsl(var(--hsl-c-light-grey-1) / .6);
        color: var(--c-blue);
      }
      &:not(.primary) > .btn-nav-anchor {
        color: var(--c-orange);
      }
      .sub-nav-anchor {
        font-weight: 400;
        font-family: $font-pr;
      }
    }
    .primary > .btn-nav-anchor[aria-expanded=true] {
      background-color: hsl(var(--hsl-c-light-grey-1) / .6);
      color: var(--c-blue);
    }
    .secondary > .btn-nav-anchor[aria-expanded='true'] {
      color: var(--c-blue-2);
    }
    .sub-nav {
      background-color: hsl(var(--hsl-c-light-grey-1) / .3);
      .sub-nav {
        background-color: unset;
      }
    }
  }
}
@media (min-width: $large-desktop-min-width) {
  .site-inside-nav {
    width: unset;
    margin-bottom: 0;
  }
}